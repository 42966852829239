import { Grid } from '@mui/material';
import { ImagePreview, UploadComponent } from 'app/shared/components';
import { useRole } from 'app/shared/hooks';
import React from 'react';

const AnexosImgPreview = ({defaultValue=[], handleUpload=()=>{}, handleRemoveFile=()=>{}, tipo, disabled}) => {
  const {isSup} = useRole();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
              <UploadComponent
                message="Escolha arquivos ou arraste aqui (JPEG, JPG e PNG) até 1MB"
                acceptFiles={{
                  "image/jpeg": [".jpeg"],
                  "image/jpg": [".jpg"],
                  "image/png": [".png"],
                }}
                onUpload={(files) => {
                  handleUpload(tipo, files);
                }}
                disabled={disabled}
              />
            </Grid>
            {defaultValue
              .filter((file) => file.tipo + "" === tipo + "")
              .map((file) => (
                <Grid item key={file.id}>
                  <ImagePreview
                    size={60}
                    imageName={file.name}
                    url={file.preview}
                    onDelete={!isSup?() => handleRemoveFile(file.id):null}
                    disabled={disabled}
                  />
                </Grid>
              ))}
          </Grid>
    );
};

export default AnexosImgPreview;