import { Grid } from '@mui/material';
import { FileViewer, UploadComponent } from 'app/shared/components';
import { useRole } from 'app/shared/hooks';
import React from 'react';

const AddFilesList = ({defaultValue=[], handleUpload=()=>{}, handleRemoveFile=()=>{}, tipo, disabled}) => {
    const {isSup} = useRole();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
              <UploadComponent
                message="Escolha arquivos ou arraste aqui (JPEG, JPG, PNG e PDF) até 10MB"
                onUpload={(files) => {
                  handleUpload(tipo, files);
                }}
                disabled={disabled}
              />
            </Grid>
            {defaultValue
              .filter((file) => file.tipo + "" === tipo + "")
              .map((file) => (
                <Grid item key={file.id}>
                  <FileViewer file={file} onDelete={!isSup?handleRemoveFile:null} disabled={disabled}/>
                </Grid>
              ))}
          </Grid>
    );
};

export default AddFilesList;