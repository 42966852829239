import {
  ListaVazia,
  StyledTableContainer,
  TableCellHover,
} from "app/shared/components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Paginacao } from "app/shared/components";
import ChipSituacao from "../ChipSituacao";
import { BsEyeFill, BsPencilFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useIsFirstRender, useService } from "app/shared/hooks";
import { LoaderComponent } from "app/shared/components";
import { useFormat } from "app/shared/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getFiltrosSelecionados } from "app/shared/helpers";
import { buscaParcelas, buscaParcelasPlanoAtual } from "app/services/parcela";
import Theme from "./../../../../app/shared/utils/Theme";
import { setPaginaAtualListagemRecursos } from "app/redux/reducers/recursoSlice";

const PAGE_LIMIT = 5;
const TableRecursos = () => {
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();
  const global = useSelector((state) => state.global);
  const recursoSelector = useSelector((state) => state.recurso);
  const mostraSomenteSemPlano = recursoSelector.mostraSomenteSemPlano;
  const pagina = recursoSelector.paginaAtualListagemRecursos;

  const [totalItems, setTotalItem] = useState(0);
  const { formataValorParaReais } = useFormat();
  const navigate = useNavigate();

  const handleLoadParcelas = (data) => {
    if (data) {
      setTotalItem(data.last_page);
    }
  };

  const { request: getParcelasReq, response: reponseParcelas } = useService(
    buscaParcelasPlanoAtual,
    false,
    handleLoadParcelas,
    () => { },
    { cache: true, cacheKey: "getParcelasPlanoAtualAll" }
  );

  const recursosLista = reponseParcelas.data?.data || [];

  const handleBuscaRecursos = () => {
    const params = {
      tipo_plano_id: global.filtros.plano,
      ano: global.ano,
      parcela: global.filtros.parcela,
      plano_status: global.filtros.status,
      page: pagina,
      limit: PAGE_LIMIT,
      sem_plano: mostraSomenteSemPlano ? 1 : undefined,
    };

    const filteredParams = getFiltrosSelecionados(params);
    getParcelasReq(filteredParams);
  };

  useEffect(() => {
    handleBuscaRecursos();
  }, [pagina, global?.filtros, global?.ano, mostraSomenteSemPlano]);

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(setPaginaAtualListagemRecursos(1));
    }
  }, [global.filtros, mostraSomenteSemPlano]);

  const handleClickRecurso = (plano, shouldGoToForm) => {
    let { plano_id, parcela, parcela_id, descricao, tipo_plano_id } = plano
    if (shouldGoToForm) {
      navigate(
        `/recurso/form?tipoPlanoId=${tipo_plano_id}&planoLabel=${descricao}&parcelaLabel=${parcela}&parcelaId=${parcela_id}`
      );
      return;
    }
    navigate(`/recurso/detalhes?id=${plano_id}`);
  };

  const isLoading = reponseParcelas.loading;
  const isEmpty = recursosLista?.length === 0 && !isLoading;
  return (
    <Grid container padding={1}>
      <LoaderComponent
        {...reponseParcelas}
        retry={handleBuscaRecursos}
        message="Buscando recursos"
        errorMessage="Falha ao carregar recursos"
      />
      {!isEmpty && !isLoading && (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography my="5px" variant="h6" color={Theme.colors.black}>
            Total: {`${reponseParcelas?.data?.total || "..."} recursos`}
          </Typography>
        </Box>
      )}
      {recursosLista?.length > 0 && (
        <>
          <StyledTableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>PLANO</TableCell>
                  <TableCell align="left">PARCELA</TableCell>
                  <TableCell align="left">CRÉDITO (R$)</TableCell>
                  <TableCell align="left">CUSTO (R$)</TableCell>
                  <TableCell align="left">SITUAÇÃO (R$)</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recursosLista?.map((recurso, key) => {
                  const shouldGoToForm = recurso?.plano_status === null;
                  const parcelaBloqueada = recurso.status_parcela === 0;
                  return (
                    <TableRow key={`row_${key}`}>
                      <TableCell align="left">{recurso.descricao}</TableCell>
                      <TableCell align="left">{recurso.parcela}</TableCell>
                      <TableCellHover align="left">
                        {formataValorParaReais(recurso?.credito) || '-'}
                      </TableCellHover>
                      <TableCell align="left">
                        {formataValorParaReais(recurso?.custo) || '-'}
                      </TableCell>
                      <TableCell align="left">
                        <ChipSituacao status={recurso?.plano_status} />
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={
                            parcelaBloqueada && shouldGoToForm
                              ? "Parcela bloqueada!"
                              : undefined
                          }
                        >
                          <span>
                            <Button
                              style={{ minWidth: 50 }}
                              color="primary"
                              variant="contained"
                              disabled={parcelaBloqueada && shouldGoToForm}
                              onClick={() => handleClickRecurso(recurso, shouldGoToForm)}
                            >
                              {shouldGoToForm ? (
                                <BsPencilFill color="white" size={20} />
                              ) : (
                                <BsEyeFill color="white" size={20} />
                              )}
                            </Button>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <Grid width="100%">
            {totalItems > 0 && (
              <Paginacao
                setNumeroPagina={(pagina) => {
                  dispatch(setPaginaAtualListagemRecursos(pagina));
                }}
                pagina={pagina}
                totalItems={totalItems}
                style={{ float: "right", padding: 10 }}
              />
            )}
          </Grid>
        </>
      )}
      <ListaVazia isListaVazia={isEmpty} />
    </Grid>
  );
};

export default TableRecursos;
